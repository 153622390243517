<template>
  <div v-if="order">
    <b-card>
      <div slot="header">
        <strong>{{ $t('order #') }}{{ order.orderNumber }}</strong>
      </div>
      <div class="orderContainer">
        <zoo-baza v-if="order.managers.length === 0 || order.managers[0].title === 'ZB'" :order="order"/>
        <zoo-market v-else-if="order.managers.length > 0 && order.managers[0].title === 'ZM'" :order="order" />
        <zoo-olx v-else :order="order" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import {APIService} from "../../services/api";
  import ZooBaza from "./components/ZooBaza";
  import ZooMarket from "./components/ZooMarket";
  import ZooOlx from "./components/ZooOlx";

  export default {
    name: 'ViewOrder',
    components: {
      ZooMarket,
      ZooBaza,
      ZooOlx,
    },
    props: ['id'],
    data: () => {
      return {
        order: null,
      }
    },
    async beforeMount() {
      try {
        const order = await APIService.get(`order/${this.id}?join=forms&join=managers&join=positions&join=deliveryDates&join=paymentMethods`)
        order.positions = order.positions.filter(position => !position.removed);
        this.order = order;
      } catch (e) {
        console.error(e);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .orderContainer {
    width: 100%;
    overflow: scroll;
    background: #fff;
    padding: 0;
    margin: 0;
  }
</style>
